export enum RuleId {
  PlayAnimalCards = 1,
  MoveAnimalTokens,
  DrawAnimalCards,
  Puffin,
  DiscardCards,
  Scoring,
  Bear,
  Walrus
}
