import { getEnumValues } from '@gamepark/rules-api'

export enum AnimalCard {
  BearFox1 = 121,
  BearFox3 = 123,
  BearFox4 = 124,
  BearFox5 = 125,
  BearMoose1 = 131,
  BearMoose2 = 132,
  BearMoose4 = 134,
  BearMoose5 = 135,
  BearOrca1 = 141,
  BearOrca2 = 142,
  BearOrca3 = 143,
  BearOrca4 = 144,
  BearPuffin2 = 152,
  BearPuffin3 = 153,
  BearPuffin4 = 154,
  BearPuffin5 = 155,
  BearWalrus1 = 161,
  BearWalrus2 = 162,
  BearWalrus3 = 163,
  BearWalrus5 = 165,
  FoxBear2 = 212,
  FoxBear3 = 213,
  FoxBear4 = 214,
  FoxBear5 = 215,
  FoxMoose1 = 231,
  FoxMoose3 = 233,
  FoxMoose4 = 234,
  FoxMoose5 = 235,
  FoxOrca1 = 241,
  FoxOrca2 = 242,
  FoxOrca3 = 243,
  FoxOrca5 = 245,
  FoxPuffin1 = 251,
  FoxPuffin2 = 252,
  FoxPuffin3 = 253,
  FoxPuffin4 = 254,
  FoxWalrus1 = 261,
  FoxWalrus2 = 262,
  FoxWalrus4 = 264,
  FoxWalrus5 = 265,
  MooseBear1 = 311,
  MooseBear2 = 312,
  MooseBear3 = 313,
  MooseBear4 = 314,
  MooseFox2 = 322,
  MooseFox3 = 323,
  MooseFox4 = 324,
  MooseFox5 = 325,
  MooseOrca1 = 341,
  MooseOrca2 = 342,
  MooseOrca4 = 344,
  MooseOrca5 = 345,
  MoosePuffin1 = 351,
  MoosePuffin2 = 352,
  MoosePuffin3 = 353,
  MoosePuffin5 = 355,
  MooseWalrus1 = 361,
  MooseWalrus3 = 363,
  MooseWalrus4 = 364,
  MooseWalrus5 = 365,
  OrcaBear1 = 411,
  OrcaBear2 = 412,
  OrcaBear4 = 414,
  OrcaBear5 = 415,
  OrcaFox1 = 421,
  OrcaFox2 = 422,
  OrcaFox3 = 423,
  OrcaFox5 = 425,
  OrcaMoose1 = 431,
  OrcaMoose2 = 432,
  OrcaMoose3 = 433,
  OrcaMoose4 = 434,
  OrcaPuffin1 = 451,
  OrcaPuffin3 = 453,
  OrcaPuffin4 = 454,
  OrcaPuffin5 = 455,
  OrcaWalrus2 = 462,
  OrcaWalrus3 = 463,
  OrcaWalrus4 = 464,
  OrcaWalrus5 = 465,
  PuffinBear1 = 511,
  PuffinBear3 = 513,
  PuffinBear4 = 514,
  PuffinBear5 = 515,
  PuffinFox1 = 521,
  PuffinFox2 = 522,
  PuffinFox4 = 524,
  PuffinFox5 = 525,
  PuffinMoose1 = 531,
  PuffinMoose2 = 532,
  PuffinMoose3 = 533,
  PuffinMoose5 = 535,
  PuffinOrca2 = 542,
  PuffinOrca3 = 543,
  PuffinOrca4 = 544,
  PuffinOrca5 = 545,
  PuffinWalrus1 = 561,
  PuffinWalrus2 = 562,
  PuffinWalrus3 = 563,
  PuffinWalrus4 = 564,
  WalrusBear1 = 611,
  WalrusBear2 = 612,
  WalrusBear3 = 613,
  WalrusBear5 = 615,
  WalrusFox1 = 621,
  WalrusFox2 = 622,
  WalrusFox3 = 623,
  WalrusFox4 = 624,
  WalrusMoose2 = 632,
  WalrusMoose3 = 633,
  WalrusMoose4 = 634,
  WalrusMoose5 = 635,
  WalrusOrca1 = 641,
  WalrusOrca3 = 643,
  WalrusOrca4 = 644,
  WalrusOrca5 = 645,
  WalrusPuffin1 = 651,
  WalrusPuffin2 = 652,
  WalrusPuffin4 = 654,
  WalrusPuffin5 = 655,
}

export const animalCards = getEnumValues(AnimalCard)

export const getAnimalFromCard = (card: AnimalCard) => {
  return Math.floor(card / 100)
}

export const getAssociatedAnimalFromCard = (card: AnimalCard) => {
  return Math.floor(card / 10 % 10)
}

export const getDrawValue = (card: AnimalCard) => {
  return (card % 10)
}

export const getDepositValue = (card: AnimalCard) => {
  return  6 - (card % 10)
}

